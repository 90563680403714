

































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Draw, immediate } from "tone";
import { IMidiDevice } from "@/shared/interfaces/devices/IMidiDevice";
import { IMidiReceiver } from "@/shared/interfaces/midi/IMidiReceiver";
import {
  MidiFunction,
  IMidiMessage,
} from "@/shared/interfaces/midi/IMidiMessage";
import { IComputerMidiKeyboardSettings } from "@/shared/interfaces/presets/IComputerMidiKeyboardSettings";
import { getDefaultKeypadSettings } from "@/services/OfflinePresetService";
import KnobControl from "@/components/KnobControl.vue";
import { v4 as uuidv4 } from "uuid";

@Component({
  components: {
    KnobControl,
  },
})
export default class ComputerMidiKeyboard extends Vue implements IMidiDevice {
  guid: string;
  name = "Computer Keyboard";
  settings: IComputerMidiKeyboardSettings;

  private readonly keyPressedColor = "#ff2929";
  private readonly blackKeys = [1, 3, 6, 8, 10];
  private connections: Array<IMidiReceiver>;
  private keysPressed: Array<boolean>;
  private mouseIsDown = false;
  private noteKeyCodes = [
    "KeyA",
    "KeyW",
    "KeyS",
    "KeyE",
    "KeyD",
    "KeyF",
    "KeyT",
    "KeyG",
    "KeyY",
    "KeyH",
    "KeyU",
    "KeyJ",
    "KeyK",
    "KeyO",
    "KeyL",
  ];

  public constructor() {
    super();
    this.guid = uuidv4();
    this.connections = [];
    this.keysPressed = new Array<boolean>(127);
    this.keysPressed.fill(false);
    this.settings = getDefaultKeypadSettings();

    document.addEventListener("keydown", this.userKeyPressed);
    document.addEventListener("keyup", this.userKeyReleased);
  }

  // Lifecycle Hooks

  mounted() {
    this.assignKeyboardListeners();
    this.$emit("deviceMounted");
  }

  beforeDestroy() {
    this.dispose();
  }

  // Methods

  applySettings(settings: IComputerMidiKeyboardSettings) {
    this.settings = settings;
    // this.updateSynthWatches(); updates watches?
  }

  connect(receiver: IMidiReceiver) {
    this.connections.push(receiver);
  }

  disconnect(receiver: IMidiReceiver) {
    const i = this.connections.indexOf(receiver);
    if (i > -1) {
      this.connections.splice(i, 1);
    } else {
      throw `no existing connection to given midi receiver`;
    }
  }

  dispose() {
    this.connections.length = 0;
    document.removeEventListener("keydown", this.userKeyPressed);
    document.removeEventListener("keyup", this.userKeyReleased);
    this.clearKeyboardListeners();
  }

  sendMidi(message: IMidiMessage) {
    this.connections.forEach((r) => {
      r.receiveMidi(message);
    });
  }

  receiveMidi(message: IMidiMessage) {
    switch (message.midiFunction) {
      case MidiFunction.noteon:
        Draw.schedule(() => {
          this.displayKeyDown(message.noteNumber);
        }, immediate());
        this.sendMidi(message);
        break;
      case MidiFunction.noteoff:
        Draw.schedule(() => {
          this.displayKeyUp(message.noteNumber);
        }, immediate());
        this.sendMidi(message);
        break;
    }
    this.sendMidi(message);
  }

  private resetKeyboardListeners() {
    this.clearKeyboardListeners();
    this.assignKeyboardListeners();
  }

  private assignKeyboardListeners() {
    const keys = document.querySelectorAll(
      "div.keyboard div.key, div.keyboard div.black-key"
    );
    for (const key of keys) {
      key.addEventListener("mousedown", this.keyMouseDown);
      key.addEventListener("mouseup", this.keyMouseUp);
      key.addEventListener("mouseover", this.keySlideOn);
      key.addEventListener("mouseout", this.keySlideOff);
      key.addEventListener("touchstart", this.keyMouseDown);
      key.addEventListener("touchend", this.keyMouseUp);
      // todo: need to implement keySlideOn and keySlideOff for touch events - see: https://gist.github.com/VehpuS/6fd5dca2ea8cd0eb0471
    }
  }

  private clearKeyboardListeners() {
    const keys = document.querySelectorAll(
      "div.keyboard div.key, div.keyboard div.black-key"
    );
    for (const key of keys) {
      key.removeEventListener("mousedown", this.keyMouseDown);
      key.removeEventListener("mouseup", this.keyMouseUp);
      key.removeEventListener("mouseover", this.keySlideOn);
      key.removeEventListener("mouseout", this.keySlideOff);
      key.removeEventListener("touchstart", this.keyMouseDown);
      key.removeEventListener("touchend", this.keyMouseUp);
    }
  }

  private getKeyNum(e: Event) {
    const el = e.target as HTMLElement;
    return parseInt(el.id.replace("key", ""));
  }

  private keySlideOn(e: Event) {
    e.stopPropagation();
    const keyNum = this.getKeyNum(e);
    if (this.mouseIsDown) {
      this.settings.chordTrigger.forEach((offset) => {
        const note = keyNum + offset;
        const n =
          note - this.settings.octaveOffset - this.settings.transposeOffset;
        if (!this.keysPressed[n]) {
          this.keysPressed[n] = true;
          Draw.schedule(() => {
            this.displayKeyDown(note);
          }, immediate());
          this.sendMidi({
            midiFunction: MidiFunction.noteon,
            noteNumber: note,
            noteVelocity: 67,
          });
        }
      });
    }
  }

  private keySlideOff(e: Event) {
    e.stopPropagation();
    const keyNum = this.getKeyNum(e);
    if (this.mouseIsDown) {
      this.settings.chordTrigger.forEach((offset) => {
        const note = keyNum + offset;
        const n =
          note - this.settings.octaveOffset - this.settings.transposeOffset;
        this.keysPressed[n] = false;
        // todo: doesn't release if you change octave or transpose while holding a key
        Draw.schedule(() => {
          this.displayKeyUp(note);
        }, immediate());
        this.sendMidi({
          midiFunction: MidiFunction.noteoff,
          noteNumber: note,
          noteVelocity: 67,
        });
      });
    }
  }

  private keyMouseDown(e: Event) {
    e.stopPropagation();
    const keyNum = this.getKeyNum(e);
    this.mouseIsDown = true;
    this.settings.chordTrigger.forEach((offset) => {
      const note = keyNum + offset;
      const n =
        note - this.settings.octaveOffset - this.settings.transposeOffset;
      if (!this.keysPressed[n]) {
        this.keysPressed[n] = true;
        Draw.schedule(() => {
          this.displayKeyDown(note);
        }, immediate());
        this.sendMidi({
          midiFunction: MidiFunction.noteon,
          noteNumber: note,
          noteVelocity: 67,
        });
      }
    });
  }

  private keyMouseUp(e: Event) {
    e.stopPropagation();
    const keyNum = this.getKeyNum(e);
    this.mouseIsDown = false;
    this.settings.chordTrigger.forEach((offset) => {
      const note = keyNum + offset;
      const n =
        note - this.settings.octaveOffset - this.settings.transposeOffset;
      this.keysPressed[n] = false;
      // todo: doesn't release if you change octave or transpose while holding a key
      Draw.schedule(() => {
        this.displayKeyUp(note);
      }, immediate());
      this.sendMidi({
        midiFunction: MidiFunction.noteoff,
        noteNumber: note,
        noteVelocity: 67,
      });
    });
  }

  private userKeyPressed(e: KeyboardEvent) {
    const n = this.noteKeyCodes.findIndex((c) => {
      return c === e.code;
    });
    // this.keysPressed needed to stop keydown from firing multiple times
    if (n > -1) {
      this.settings.chordTrigger.forEach((offset) => {
        if (!this.keysPressed[n + offset]) {
          this.keysPressed[n + offset] = true;
          const note =
            n +
            offset +
            this.settings.octaveOffset +
            this.settings.transposeOffset;
          Draw.schedule(() => {
            this.displayKeyDown(note);
          }, immediate());
          this.sendMidi({
            midiFunction: MidiFunction.noteon,
            noteNumber: note,
            noteVelocity: 67,
          });
        }
      });
    }
  }

  private userKeyReleased(e: KeyboardEvent) {
    const n = this.noteKeyCodes.findIndex((c) => {
      return c === e.code;
    });
    if (n > -1) {
      this.settings.chordTrigger.forEach((offset) => {
        this.keysPressed[n + offset] = false;
        // todo: doesn't release if you change octave or transpose while holding a key
        const note =
          n +
          offset +
          this.settings.octaveOffset +
          this.settings.transposeOffset;
        Draw.schedule(() => {
          this.displayKeyUp(note);
        }, immediate());
        this.sendMidi({
          midiFunction: MidiFunction.noteoff,
          noteNumber: note,
          noteVelocity: 67,
        });
      });
    } else if (e.code === "KeyZ") {
      // go down an octave
      this.settings.octaveOffset = Math.max(this.settings.octaveOffset - 12, 0);
    } else if (e.code === "KeyX") {
      // go up an octave
      this.settings.octaveOffset = Math.min(
        this.settings.octaveOffset + 12,
        120
      );
    } else if (e.code === "KeyC") {
      // transpose down a step
      this.settings.transposeOffset = Math.max(
        this.settings.transposeOffset - 1,
        -126
      );
    } else if (e.code === "KeyV") {
      // transpose up a step
      this.settings.transposeOffset = Math.min(
        this.settings.transposeOffset + 1,
        126
      );
    }
  }

  private displayKeyDown(keyNumber: number) {
    const key: HTMLElement | null = document.querySelector(`#key${keyNumber}`);
    if (key != null) {
      key.style.background = this.keyPressedColor;
    }
  }

  public displayKeyUp(keyNumber: number) {
    const key: HTMLElement | null = document.querySelector(`#key${keyNumber}`);
    if (key != null) {
      key.style.background = this.blackKeys.includes(keyNumber % 12)
        ? "black"
        : "white";
    }
  }

  // Watches

  @Watch("$vuetify.breakpoint.name")
  private onBreakpointChange(value: string) {
    this.resetKeyboardListeners();
  }
}
