





























import { Component, Vue, Prop } from "vue-property-decorator";
import { Octokit } from "@octokit/rest";

@Component({})
export default class ChangeLog extends Vue {
  @Prop({ required: true })
  public value!: boolean;
  private commitMessages: string[];
  private title = "Change Log";

  constructor() {
    super();
    this.commitMessages = [];
  }

  mounted() {
    const octokit = new Octokit();
    octokit.repos
      .listCommits({
        owner: "ckind",
        repo: "JvaSynth",
      })
      .then((response) => {
        response.data.forEach((c) => {
          this.commitMessages.push(c.commit.message);
        });
      })
      .catch(() => {
        this.title = "Error Loading Commits";
      });
  }

  private closeDialog() {
    this.$emit("input", false);
  }
}
