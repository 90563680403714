













































import { Component, Vue, Prop } from "vue-property-decorator";
import { IEffectsDevice } from "@/shared/interfaces/devices/IEffectsDevice";
import { v4 as uuidv4 } from "uuid";
import { ToneAudioNode, Gain as ToneGain, context as ToneContext } from "tone";

@Component({})
export default class Visualizer extends Vue implements IEffectsDevice {
  public guid: string;
  public output: ToneAudioNode;
  public input: ToneAudioNode;
  public name: string;
  public settings: any;

  private analyser: AnalyserNode;
  private dataArray: Float32Array;
  private bufferLength: number;
  private canvasContext!: CanvasRenderingContext2D; // tell the typescript compiler to take a chill pill - we'll set it in mounted()
  private continueDrawing = true;
  private fillColor = "#70bfff";
  private nyquistFrequency: number;
  private fftSize = 1024;

  $refs!: {
    analyserCanvas: HTMLCanvasElement;
  };

  @Prop({ required: false, default: 208 })
  public width!: number;

  @Prop({ required: false, default: 130 })
  public height!: number;

  get cssVars() {
    return {
      "--shadowColor": "#5e5e5e",
    };
  }

  constructor() {
    super();

    this.guid = uuidv4();
    this.output = new ToneGain(1);
    this.input = new ToneGain(1);
    this.name = "Visualizer";
    this.settings = {};

    this.analyser = ToneContext.createAnalyser();
    this.analyser.fftSize = this.fftSize;
    this.bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Float32Array(this.bufferLength);
    this.nyquistFrequency = ToneContext.sampleRate / 2;

    this.input.chain(this.analyser, this.output);
  }

  // Lifecycle Hooks

  mounted() {
    this.canvasContext = this.$refs.analyserCanvas.getContext("2d")!;
    // window.requestAnimationFrame(this.drawFrequencyDomain);
    window.requestAnimationFrame(this.drawTimeDomain);

    this.$emit("effectsDeviceMounted", this.guid);
  }

  beforeDestroy() {
    this.continueDrawing = false;
    this.dispose();
  }

  // Methods

  deleteComponent() {
    this.$emit("deleteComponent", this);
  }

  componentDragstart() {
    this.$emit("componentDragstart", this);
  }

  componentDragend() {
    this.$emit("componentDragend", this);
  }

  elementDropped() {
    this.$emit("elementDropped", this);
  }

  drawTimeDomain() {
    this.analyser.getFloatTimeDomainData(this.dataArray);

    this.canvasContext.fillStyle = "black";
    this.canvasContext.fillRect(0, 0, this.width, this.height);

    this.canvasContext.lineWidth = 2.5;
    this.canvasContext.strokeStyle = this.fillColor;

    this.canvasContext.beginPath();

    const sliceWidth = this.width / this.bufferLength;

    let x = 0;

    for (let i = 0; i < this.bufferLength; i++) {
      const v = this.dataArray[i] * this.height * 0.8;
      const y = this.height / 2 + v;

      if (i === 0) {
        this.canvasContext.moveTo(x, y);
      } else {
        this.canvasContext.lineTo(x, y);
      }

      x += sliceWidth;
    }

    this.canvasContext.lineTo(this.width, this.height / 2);
    this.canvasContext.stroke();

    if (this.continueDrawing) {
      window.requestAnimationFrame(this.drawTimeDomain);
    }
  }

  drawFrequencyDomain() {
    this.analyser.getFloatFrequencyData(this.dataArray);

    this.canvasContext.fillStyle = "black";
    this.canvasContext.fillRect(0, 0, this.width, this.height);

    for (let i = 0; i < this.bufferLength; i++) {
      const freq = i * (this.nyquistFrequency / this.fftSize);

      const x = i * (this.width/this.bufferLength);
      const barWidth = 1;
      const barHeight = this.dataArray[i] + 140;

      this.canvasContext.fillStyle = this.fillColor;
      this.canvasContext.fillRect(
        x,
        this.height - barHeight,
        barWidth,
        barHeight
      );
    }

    if (this.continueDrawing) {
      window.requestAnimationFrame(this.drawFrequencyDomain);
    }
  }

  applySettings(settings: any) {
    this.settings = settings;
  }

  dispose() {
    this.input.dispose();
    this.output.dispose();
  }
}
