




































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ToneOscillatorType } from "tone";

@Component({})
export default class WaveformSelector extends Vue {
  private waveforms: Array<ToneOscillatorType>;
  private myValue: ToneOscillatorType;

  // for some reason vue complains about this if its required
  // even if you have v-model
  @Prop({ required: false })
  public value!: ToneOscillatorType;

  constructor() {
    super();

    this.myValue = "square";

    this.waveforms = new Array<ToneOscillatorType>(4);
    this.waveforms[0] = "sine";
    this.waveforms[1] = "triangle";
    this.waveforms[2] = "sawtooth";
    this.waveforms[3] = "square";
  }

  changeType(i: number) {
    this.myValue = this.waveforms[(i + 1) % this.waveforms.length];
    this.$emit('input', this.myValue);
  }

  @Watch("value")
  onValueChanged(incomingValue: ToneOscillatorType) {
    this.myValue = incomingValue;
  }
}
