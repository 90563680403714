



















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class PageSelector extends Vue {
  private selectedOption = 0;

  @Prop({ required: true })
  public options!: any[];

  @Prop({ required: false, default: false })
  public dark!: boolean;

  @Prop({ required: false, default: false })
  public slim!: boolean;

  constructor() {
    super();
  }

  mounted() {
    this.pageSelected(this.options[0], 0);
  }

  pageSelected(option: any, i: number) {
    this.selectedOption = i;
    this.$forceUpdate();
    this.$emit("pageSelected", option);
  }
}
