var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex",attrs:{"draggable":""},on:{"dragstart":this.componentDragstart,"dragend":this.componentDragend,"drop":_vm.elementDropped,"dragover":function (e) {
      e.preventDefault();
    }}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',{staticClass:"analyser-container",style:(_vm.cssVars),on:{"contextmenu":function (e) {
            e.preventDefault();
            on.click(e);
          }}},[_c('h3',{staticClass:"center-x mb-2"},[_vm._v(_vm._s(_vm.name))]),_c('canvas',{ref:"analyserCanvas",staticClass:"analyser-canvas",attrs:{"height":_vm.height,"width":_vm.width}})])]}}])},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteComponent($event)}}},[_c('v-list-item-title',[_vm._v("delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }