




















































import { Component, Vue, Prop } from "vue-property-decorator";
import { IPresetBank } from "@/shared/interfaces/presets/IPresetBank";
import { IPreset } from "@/shared/interfaces/presets/IPreset";

@Component({})
export default class PresetDropdown extends Vue {
  @Prop({ required: true })
  public bank!: IPresetBank;

  @Prop({ required: true })
  public selectedPreset!: IPreset;

  @Prop({ required: true })
  public label!: string;

  private showMenu = false;

  constructor() {
    super();
  }

  presetSelected(p: IPreset) {
    this.showMenu = false;
    this.$emit("presetSelected", p);
  }
}
