var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex",attrs:{"draggable":""},on:{"dragstart":this.componentDragstart,"dragend":this.componentDragend,"drop":_vm.elementDropped,"dragover":function (e) {
      e.preventDefault();
    }}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',{staticClass:"dist-container",on:{"contextmenu":function (e) {
            e.preventDefault();
            on.click(e);
          }}},[_c('h3',{staticClass:"center-x mb-2"},[_vm._v(_vm._s(_vm.name))]),_c('knob-control',{attrs:{"minValue":0,"maxValue":1,"label":"Mix","id":"dryWet","size":"50","shadowColor":'#5e5e5e'},model:{value:(_vm.settings.mix),callback:function ($$v) {_vm.$set(_vm.settings, "mix", $$v)},expression:"settings.mix"}}),_c('knob-control',{attrs:{"minValue":0.001,"maxValue":1,"label":"Drive","id":"Drive","size":"50","shadowColor":'#5e5e5e'},model:{value:(_vm.settings.drive),callback:function ($$v) {_vm.$set(_vm.settings, "drive", $$v)},expression:"settings.drive"}})],1)]}}])},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteComponent($event)}}},[_c('v-list-item-title',[_vm._v("delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }