














import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { NoiseType as ToneNoiseType } from "tone";

@Component({})
export default class NoiseTypeSelector extends Vue {
  private noiseTypes: Array<ToneNoiseType>;
  private myValue: ToneNoiseType;

  // for some reason vue complains about this if its required
  // even if you have v-model
  @Prop({ required: false })
  public value!: ToneNoiseType;

  constructor() {
    super();

    this.myValue = "white";

    this.noiseTypes = new Array<ToneNoiseType>(3);
    this.noiseTypes[0] = "white";
    this.noiseTypes[1] = "pink";
    this.noiseTypes[2] = "brown";
  }

  changeType(i: number) {
    this.myValue = this.noiseTypes[(i + 1) % this.noiseTypes.length];
    this.$emit("input", this.myValue);
  }

  @Watch("value")
  onValueChanged(incomingValue: ToneNoiseType) {
    this.myValue = incomingValue;
  }
}
