























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IEffectsDevice } from "@/shared/interfaces/devices/IEffectsDevice";
import KnobControl from "@/components/KnobControl.vue";
import { v4 as uuidv4 } from "uuid";
import {
  ToneAudioNode,
  Gain as ToneGain,
  Distortion as ToneDistortion,
  Signal as ToneSignal,
} from "tone";

interface IDistortionSettings {
  mix: number;
  drive: number;
}

@Component({
  components: {
    KnobControl,
  },
})
export default class Distortion extends Vue implements IEffectsDevice {
  public guid: string;
  public name: string;
  public settings: IDistortionSettings;

  public input!: ToneAudioNode;
  public output!: ToneAudioNode;
  private toneDistortion!: ToneDistortion;

  constructor() {
    super();

    this.guid = uuidv4();
    this.name = "Distortion";
    this.settings = {
      mix: 1.0,
      drive: 0.4,
    };
  }

  // Lifecycle Hooks

  created() {
    this.input = new ToneGain(1);
    this.output = new ToneGain(1);
    this.toneDistortion = new ToneDistortion(0.4);

    this.input.connect(this.toneDistortion);
    this.toneDistortion.connect(this.output);
  }

  beforeDestroy() {
    this.dispose();
  }

  // Methods

  deleteComponent() {
    this.$emit("deleteComponent", this);
  }

  componentDragstart() {
    this.$emit("componentDragstart", this);
  }

  componentDragend() {
    this.$emit("componentDragend", this);
  }

  elementDropped() {
    this.$emit("elementDropped", this);
  }

  applySettings(settings: any) {
    this.settings = settings;
  }

  dispose() {
    this.input.disconnect(this.toneDistortion);
    this.toneDistortion.disconnect(this.output);

    this.input.dispose();
    this.output.dispose();
    this.toneDistortion.dispose();
  }

  // Watch

  @Watch("settings.mix")
  private setMix(value: number) {
    this.toneDistortion.wet.value = value;
  }

  @Watch("settings.drive")
  private setDrive(value: number) {
    this.toneDistortion.distortion = value;
  }
}
