






































import { Component, Vue, Prop } from "vue-property-decorator";
import { IPreset } from "../shared/interfaces/presets/IPreset";
import { IPresetBank } from "../shared/interfaces/presets/IPresetBank";
import PresetDropdown from "./PresetDropdown.vue";
import DeviceDropdown from "./DeviceDropdown.vue";

@Component({
  components: {
    DeviceDropdown,
    PresetDropdown,
  },
})
export default class DeviceContainerModal extends Vue {
  @Prop({ required: true })
  public value!: boolean;

  @Prop({ required: true })
  public currentPresetBank!: IPresetBank;

  @Prop({ required: true })
  public currentPreset!: IPreset;

  @Prop({ required: true })
  public currentDeviceName!: string;

  @Prop({ required: true })
  public availableDevices!: string[];

  constructor() {
    super();
  }

  private closeDialog() {
    this.$emit("input", false);
  }

  private deviceSelected(d: string) {
    this.$emit("deviceSelected", d);
  }

  private presetSelected(p: IPreset) {
    this.$emit("presetSelected", p);
  }
}
