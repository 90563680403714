















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class QuickStart extends Vue {
  @Prop({ required: true })
  public value!: boolean;

  constructor() {
    super();
  }

  closeDialog() {
    this.$emit("input", false);
  }
}
