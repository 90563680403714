
























import { Component, Vue, Prop } from "vue-property-decorator";
import { IDevice } from "@/shared/interfaces/devices/IDevice";

@Component({})
export default class DeviceDropdown extends Vue {
  @Prop({ required: true })
  public devices!: string[];

  @Prop({ required: true })
  public selectedDeviceName!: string[];

  @Prop({ required: true })
  public label!: string;

  constructor() {
    super();
  }

  deviceSelected(deviceName: string) {
    this.$emit("deviceSelected", deviceName);
  }
}
